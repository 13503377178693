.module-footer {
  background-color: $color-primary;
  > .module {
    &-inner {
      .footer {
        &__links {
          .list-inline {
            &-item {
              a {
                color:$color-white;
              }
            }
          }
        }
      }
      .copyright {
        &__content {
          .list-inline {
            &-item {
              color: $color-white;
              a{
                color: $color-white;
              }
            }
          }
        }
      }
      .social-networks {
        color: $color-white;
        .fa {
          color: $color-white;
        }
      }
    }
  }
}